import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
  },
  texts: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "40px auto 40px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "100px auto 80px",
    },
  },
  header: {
    fontSize: "34px",
    lineHeight: "1.3",
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 10,
  },
  description: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
  },
  tableRow: {
    padding: "0px 20%",
    display: "flex",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tableCell: {
    padding: "13px 4px 14px",
    display: "flex",
    alignItems: "center",
    borderBottom: "unset",
  },
  firstTableCell: {
    width: "37.5%",
  },
  normalTableCell: {
    width: "25%",
  },
  lastTableCell: {
    width: "12.5%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkTitle: {
    fontSize: "1rem",
    lineHeight: "1.2rem",
    fontWeight: "600",
    letterSpacing: ".04em",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  linkSubtitle: {
    fontWeight: "500",
    fontSize: "0.75rem",
    letterSpacing: ".04em",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
}));

const Masternodes = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <section>
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            Cryptocurrency Masternodes
          </Typography>
        </div>
      </section>
    </div>
  );
};

export default Masternodes;
