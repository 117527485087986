import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";
import Masternodes from "../components/Masternodes/Masternodes";

const MasternodesPage = () => (
  <Root>
    <SEO title="Best Masternode Pools & Staking Services" description="View masternode pools and other services in order to buy entire masternodes or their shares and earn passive income as a masternode co-owner." />
    <Masternodes />
  </Root>
);

export default MasternodesPage;
